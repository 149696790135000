import api from '@/api/team'
import { VueEditor } from 'vue2-editor'
import {notify} from "@/helpers/notifyHelper";

export default {
	name: 'Create',
	data () {
		return {
			valid: false,
			formData: {
				name: '',
				title: null,
				description: null,
				image: null,
				urlName: null,
				titleImage: null,
				metaTitle: '',
				metaDescription: '',
				metaKeywords: ''
			}
		}
	},
	components: {
		VueEditor
	},
	methods: {
		create () {
			if (this.$refs.form.validate()) {
				var regExpression = /^[a-zA-Z0-9-]*$/
				if (!regExpression.test(this.formData.urlName)) {
					notify(this, 'error', 'Link may be string and - ')
					return
				}

				let data = new FormData()
				Object.keys(this.formData).map(key => {
					data.append(key, this.formData[key])
				})

				api.create(this, data)
					.then(() => {
						this.$router.push({name: 'TeamList'})
					})
			}
		}
	}
}

